<template>
  <div class="me-image-mark">
    <div
      v-if="src && src.length"
      class="me-image-mark-content"
    >
      <div
        :style="{
          transform: `scale(${scale})`,
        }"
      >
        <img
          :src="src"
          :width="width"
          @load="loadImg"
          class="me-image-mark-image"
          @click="clickImg"
        />
        <point
          v-for="(point, index) in value"
          :key="`${point.x}${point.y}-${index}`"
          :point="point"
          :basis="basis"
          :index="index"
          :gather="gather"
          :img-height="height"
          :img-width="width"
          @update="updateInfo"
          @clickPoint="clickPoint"
        >
          <template slot-scope="{ detail }">
            <slot :point="detail"></slot>
          </template>
          <template
            slot="icon"
            slot-scope="{ detail }"
          >
            <slot
              name="icon"
              :point="detail"
            ></slot>
          </template>
        </point>
      </div>
    </div>
    <div
      class="me-image-mark-empty"
      v-else
    >
      暂无标注信息
    </div>
  </div>
</template>

<script>
import Point from "./point.vue"
import Bus from "./bus"
export default {
  name: "image-mark",
  components: { Point },
  data() {
    return {
      //图片缩放比例
      scale: 1,
      //图片尺寸基准大小
      basis: 1,
      //图片初始化宽度
      width: 0,
      //图片初始化高度
      height: 0,
    }
  },
  watch: {
    src: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            const img = new Image()
            img.src = val
            this.$nextTick(() => {
              const clientWidth = this.$el.clientWidth
              this.width = clientWidth
              const clientHeight = this.$el.clientHeight
              this.height = clientHeight
              this.$emit("update:basisWidth", this.width)
              if (this.gather) {
                this.basis = 1
              } else {
                this.basis = this.width / this.basisWidth
              }
            })
          })
        }
      },
    },
  },
  props: {
    src: String,
    //点位数据
    value: {
      type: Array,
      default: () => [],
    },
    // 是否运行对图片进行操作
    disabled: {
      type: Boolean,
      default: false,
    },
    // 基准宽度，当拾取坐标点时会保存响应的宽度。
    basisWidth: Number,
    // 是否为采集模式
    gather: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickPoint(index, point) {
      this.$emit("clickPoint", point, index)
    },
    loadImg(event) {
      const naturalWidth = event.target.naturalWidth
      const naturalHeight = event.target.naturalHeight
      const height = (naturalHeight / naturalWidth) * this.basisWidth
      this.height = height
    },
    clickImg() {
      this.$emit("clickImg")
    },
    getPoints(event) {
      if (!this.gather) return
      const x = event.offsetX / this.basis
      const y = event.offsetY / this.basis
      const list = JSON.parse(JSON.stringify(this.value))
      list.push({ x: parseInt(x), y: parseInt(y) })
      this.$emit("input", list)
    },

    updateInfo(point, index) {
      const list = JSON.parse(JSON.stringify(this.value))
      list[index] = point
      this.$emit("input", list)
    },
    //插入点位
    insertPoints(point) {
      list.push({ x: parseInt(point.x), y: parseInt(point.y) })
      this.$emit("input", list)
    },
    removePoint(index) {
      if (this.disabled) return
      const list = JSON.parse(JSON.stringify(this.value))
      list.splice(index, 1)
      this.$emit("input", list)
    },
    addSize() {
      this.scale += 0.1
      if (this.scale > 2.0) {
        this.scale = 2.0
      }
      Bus.$emit("show-popover", {})
      this.$forceUpdate()
    },
    deleteSize() {
      this.scale -= 0.1
      if (this.scale < 0.5) {
        this.scale = 0.5
      }
      Bus.$emit("show-popover", {})
      this.$forceUpdate()
    },
  },
}
</script>

<style lang="less" scoped>
.me-image-mark {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  height: fit-content;
  flex-shrink: 0;
  &-empty {
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
}
.me-image-mark-content {
  position: relative;
  overflow: auto;
  & > div {
    transition: transform 0.5s ease-in-out;
    transform-origin: 50% 50%;
  }
}
.me-image-mark-image {
  user-select: none;
  -webkit-user-drag: none;
  width: 100%;
}
</style>
